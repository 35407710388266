import { useEffect, useState, useContext, useMemo } from 'react'
import { useTheme } from '@mui/material/styles'
import {
  Container,
  Box,
  Button,
  Paper,
  TextField,
  Stack,
  Typography,
  CircularProgress,
  LinearProgress,
  Alert,
  AlertTitle,
} from '@mui/material'
import {
  Routes,
  Route,
  Outlet,
  Link,
  useNavigate,
  useLocation,
} from 'react-router-dom'
import { motion } from 'framer-motion'
import $axios from '../../tools/axiosWrapper'
import { useForm } from 'react-hook-form'
import { randomUniform } from 'd3-random'
import { DataGridPremium } from '@mui/x-data-grid-premium'
import LinkIcon from '@mui/icons-material/Link'
import SummarizeIcon from '@mui/icons-material/Summarize'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import _ from 'lodash'

export const MasterText = ({ }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const [results, setResults] = useState(null)

  useEffect(() => {
    $axios.get('/collection/list/green').then((d) => {
      console.log(d)
      setResults(
        Object.entries(d.data.images).map(([key, val]) => ({ key, ...val }))
      )
    })
  }, [])

  const COLUMNS = [
    {
      field: 'key',
      headerName: 'link',
      width: 280,
      resizable: false,
      renderCell: (props) => {
        const { hasFocus, value } = props
          return (
            <>
              <a
                className={'linktable'}
                href={'user/doc/' + value}
              >
                <LinkIcon />
                <div>{'user/doc/' + value.replace('w32', '')}</div>
              </a>
            </>
          )
      },
    },
    {
      field: 'title',
      headerName: 'document title',
      width: 300,
      resizable: true,
    },
    {
      field: 'summary_short',
      headerName: 'document summary',
      width: 500,
      resizable: true,
    },
    // {
    //   field: 'images',
    //   headerName: 'images',
    //   width: 100,
    //   resizable: false,
    //   renderCell: (props) => {
    //     const { hasFocus, value } = props
    //     return (
    //       <>
    //         <div style={{ fontWeight: 600 }}>{value?.length}</div>
    //       </>
    //     )
    //   },
    // },
  ]

  return (
    <>
      {(
        <Stack spacing={2} sx={{ my: 6, minHeight: '600px', mb: 20 }}>
          {results && (
            <>
              <Typography variant={'h6'}>Master List of Documents:</Typography>
              <Box sx={{ height: '600px' }}>
                {results && (
                  <DataGridPremium
                    pagination
                    // checkboxSelection
                    initialState={{
                      pagination: { paginationModel: { pageSize: 820 } },
                      sorting: {
                        sortModel: [{ field: 'title', sort: 'asc' }],
                      },
                    }}
                    sx={{ '& .MuiDataGrid-cell': { padding: '12px' } }}
                    getRowId={(row) => row.key}
                    columns={COLUMNS}
                    rows={results}
                    getRowHeight={() => 'auto'}
                    virtualizeColumnsWithAutoRowHeight={true}
                  />
                )}
              </Box>
              <Button
                variant={'outlined'}
                size={'large'}
                onClick={() => navigate('/collection-text/summaries')}
              >
                view summaries of text
                <SummarizeIcon sx={{ ml: 1 }} />
              </Button>
            </>
          )}
        </Stack>
      )}
    </>
  )
}
