import { useEffect, useState, useContext, useMemo, Fragment } from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import {
  Container,
  Box,
  Stack,
  Modal,
  Typography,
  TextField,
  Button,
  LinearProgress,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { motion } from 'framer-motion'
import { Like } from './like'
import { TagItem } from './tagitem'
import _ from 'lodash'
import $axios from '../../tools/axiosWrapper'

const linkStyle = {
  color: '#7979f0',
}


const StaticSummaries = ({ user }) => {
  const theme = useTheme()
  const [results, setResults] = useState(null)
  const [load, setLoad] = useState(true)

  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    document.documentElement.scrollTo(0, 0)
  }, [])

  useEffect(() => {
      setLoad(true)

      $axios
          .get('/collection/list/green')
          .then((response) => {
              setResults(response.data);
          })
          .catch((er) => console.log(er))
          .finally(() => setLoad(false))
  }, [])

  return (
    <>
      {load ? (
          <LinearProgress />
      ) : (
          <Box sx={{ height: '4px', width: '100vw' }}></Box>
      )}

      {(!load) && (
        <>
          <Stack sx={{ minHeight: '100vh', py: 12 }} alignItems={'center'}>
            <Typography
              variant='h5'
              sx={{ fontWeight: 800, color: theme.palette.purple[5], mb: 8 }}
            >
              Document Summaries
            </Typography>

            <Stack sx={{ width: 700, minHeight: '100vh' }} spacing={10}>
              

                {Object.values(results.images).map((r, index) => {
                  return <Stack spacing={2} key={index}>

                    <Typography variant={'h6'}>
                      {r.title} - <Link to={'/user/doc/' + r.docid} style={linkStyle}>{r.docid}</Link>
                    </Typography>
                    
                    <Typography variant={'body1'}>{r.summary_long}</Typography>
                  </Stack>
                })}


                
            </Stack>
          </Stack>

          <hr />

          <Stack
            style={{
              width: '100vw',
              minHeight: '100vh',
            }}
            alignItems={'center'}
            sx={{ pr: 8 }}
          >
            <Stack
              direction={'row'}
              spacing={2}
              justifyContent={'center'}
              alignItems={'center'}
              sx={{ mt: 4 }}
            ></Stack>

            <Stack
              sx={{
                mt: 10,
                px: 4,
                py: 8,
                width: '80vw',
                maxWidth: 1000,
                // background: theme.palette.purple[0],
                borderRadius: '10px',
                overflowY: 'scroll',
              }}
              spacing={8}
            >
              <Stack sx={{ width: '100%' }} alignItems='center' textAlign='center'>
                <Typography marginBottom={2}>
                  Thank you for your interest in FBarchive. In order to view these
                  images, please log in by clicking the button below.
                </Typography>
                <Button
                  variant={'contained'}
                  size={'large'}
                  onClick={() => {
                    $axios.get('/auth/login').then((response) => {
                      window.location.href = response.data.login_url
                    })
                  }}
                >
                  log in
                </Button>
              </Stack>

              <Stack sx={{ width: '100%' }} alignItems='center' textAlign='center'>
                <Typography marginBottom={2}>
                  Don’t have an account? Sign up now by clicking the below button.
                </Typography>
                <Button
                  variant={'contained'}
                  size={'large'}
                  onClick={() => {
                    $axios.get('/auth/login').then((response) => {
                      window.location.href = response.data.login_url.replace(
                        'login',
                        'signup'
                      )
                    })
                  }}
                >
                  sign up
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </>
      )}

    </>
  )
}

export default StaticSummaries
