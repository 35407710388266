import { useState, useEffect } from 'react'
import {
  Route,
  Routes,
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { Head } from './new/head/head'
import { Home } from './new/home/home'
import { Desk } from './new/desk/desk'
import Doc from './new/page/doc'
import { Likes } from './new/page/likes'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import theme from './theme'
import $axios from './tools/axiosWrapper'
import { History } from './new/display/history'
import { Updates } from './new/display/updates'
import { Redaction } from './new/display/redaction'
import { RedactionPrivacy } from './new/display/redaction-privacy'
import { Faq } from './new/display/faq'
import { HowTo } from './new/display/howto'
import { Motivation } from './new/display/motivation'
import { Citing } from './new/display/citing'
import { About } from './new/display/about'
import { Terms } from './new/page/terms'
import { Tags } from './new/page/tags'
import { Collection } from './new/page/collection'
import { CollectionText } from './new/page/collection-text'

// import Viz from './new/viz/viz/viz.js'
import Human from './new/viz/human/human.js'
import Mentions from './new/viz/viz4/mentions.js'
import Slides from './new/viz/slides/slides.js'

import { LicenseInfo } from '@mui/x-license-pro'
import './App.css'
import { set } from 'lodash'
import StaticDoc from './new/page/static-doc'
import StaticSummaries from './new/page/static-summaries'

LicenseInfo.setLicenseKey(
  'fbd9d5c99e4e275407d326256727a107Tz01MjUxNixFPTE2OTc0MDMyNzQ5MTAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
)

export const App = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [user, setUser] = useState(null)
  const [search, setSearch] = useState('')
  const [results, setResults] = useState(null)

  useEffect(() => {
    $axios
      .get('/auth/me')
      .then((response) => {
        setUser(response.data)
      })
      .catch((err) => setUser(false))
    $axios
      .post('/auth/register')
      .then((response) => {})
      .catch((err) => {})
  }, [])

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes location={location} key={location.pathname}>
          <Route
            path='/'
            element={
              <Head user={user} setSearch={setSearch} setResults={setResults} />
            }
          >
            <Route
              index
              element={
                <Home
                  user={user}
                  search={search}
                  setSearch={setSearch}
                  results={results}
                  setResults={setResults}
                />
              }
            />
            <Route path='/doc/:id' element={<Doc user={user} />} />
            <Route path='/user/doc/:id' element={<StaticDoc user={user} />} />
            <Route path='/collection' element={<Collection user={user} />} />
            <Route path='/collection-text' element={<CollectionText />} />
            <Route path='/collection-text/summaries' element={<StaticSummaries />} />
            <Route path='/saved' element={<Likes user={user} />} />
            <Route path='/tags' element={<Tags user={user} />} />
            <Route path='/history' element={<History />} />
            <Route path='/updates' element={<Updates />} />
            <Route path='/redaction' element={<Redaction />} />
            <Route path='/redaction-privacy' element={<RedactionPrivacy />} />
            <Route path='/faq' element={<Faq />} />
            <Route path='/explorations' element={<Motivation />} />
            <Route path='/citing' element={<Citing />} />
            <Route path='/about' element={<About />} />
            <Route path='/tutorial' element={<HowTo />} />
            <Route path='/terms' element={<Terms />} />
            {/* <Route path={'viz'} element={<Viz />} /> */}
            <Route path={'viz2'} element={<Human />} />
            {/* <Route path={'viz3'} element={<Slides />} /> */}
            <Route path={'viz4'} element={<Mentions />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </>
  )
}
