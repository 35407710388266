import { useEffect, useState, useContext, useMemo } from 'react'

import { Container, Grid, Typography, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Link } from 'react-router-dom'

import img2023May from './history_images/2023-may.png'
import img2023Jan from './history_images/2023-jan.png'
import img2022Nov from './history_images/2022-nov.png'
import img2022Feb from './history_images/2022-feb.png'
import img2022Jan from './history_images/2022-jan.png'
import img2021Nov from './history_images/2021-nov.png'
import img2021Oct from './history_images/2021-oct.png'

const imageStyle = {
  width: '100%',
  filter: 'grayscale(1) invert(1)',
}

const linkStyle = {
  color: '#7979f0',
}

export const History = () => {
  const theme = useTheme()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Stack sx={{ minHeight: '100vh', py: 12 }} alignItems={'center'}>
        <Typography
          variant='h2'
          sx={{ fontWeight: 800, color: theme.palette.purple[5], mb: 8 }}
        >
          HISTORY
        </Typography>

        <Stack sx={{ width: 700 }} spacing={6}>
          <Stack spacing={2}>
            <Typography variant='h4'>March 2025</Typography>
            <Typography variant='body1'>
              Full Text-Only Summaries and AI-Generated Summaries. Each document in FBarchive is now 
              also available in a text-only format through direct public links for reading 
              ease and reference. You can access the full index of text-only documents at <Link style={linkStyle} to="/collection-text">fbarchive.org/collection-text</Link>. Each document is also summarized using generative AI, 
              making key insights more accessible. The summaries 
              are available at <Link style={linkStyle} to="/collection-text/summaries">fbarchive.org/collection-text/summaries</Link>.
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography variant='h4'>February 2025</Typography>
            <Typography variant='body1'>
              TechScience.org published two scientific papers sourced from documents on FBarchive.org 
              about content moderation on Meta’s platforms. <a style={linkStyle} href="https://techscience.org/a/2025022503/">Facebook’s Search Interventions: 
              Bad in English, Peor en Español</a> reports that when searching in Spanish, Facebook’s 
              search was far easier to access content that violates the Community Standards, 
              including violent, sexually explicit, and harmful content. This study found 
              that 49% of English language words that should violate Meta’s Community 
              Standards triggered search interventions, 
              whereas only 21% of similar Spanish words did. <a style={linkStyle} href="https://techscience.org/a/2025022501/">Linguistic Inequity in Facebook Content Moderation</a> examines the practice, revealed on 
              FBarchive, that Facebook relies on automated translation tools to convert non-English 
              content into English for review by English-speaking moderators. This practice introduces 
              significant risks: inaccurate translations can lead to wrongful takedowns of benign 
              content while allowing harmful posts to remain online. A survey comparing English speakers’ 
              perceptions of translated Mandarin content with native Mandarin speakers' assessments found 
              a statistically significant gap in how each group judged the content’s permissibility. 
              This discrepancy underscores the dangers of automated translation in moderation, creating 
              linguistic inequities that disproportionately impact non-Western users.
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography variant='h4'>December 2024</Typography>
            <Typography variant='body1'>
              The Public Interest Tech Lab has successfully published the remaining documents received in December 2023. 
              Under the leadership of Dr. Gabrielle Malina, the team followed the established 
              procedures used for the initial batch, which included converting images to text, 
              applying redactions as dictated by the Privacy & Safety Board's policies, 
              allowing Meta to request further redactions, and translating any foreign 
              language content. The comprehensive final set now consists of 29,283 images organized 
              into 1,428 documents. Team members include (in alphabetical order): Erika Brochu, 
              Christian DeCoster, Gabrielle Malina (lead), and Linda Osei.
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography variant='h4'>December 2023</Typography>
            <Typography variant='body1'>
              In December 2023, Professor Sweeney and the Public Interest Tech Lab received a 
              full set of the internal Facebook documents leaked by whistleblower Frances Haugen in 2021. 
              After reviewing the full set, the team determined that the collection 
              of documents originally received for FBarchive only contained about two 
              thirds of all the leaked documents. Haugen confirmed for the team that 
              the newly received set was complete. Over the next weeks, the Public Interest 
              Tech Lab will work quickly to add the newly received documents 
              to the FBarchive so that the full set is available.
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography variant='h4'>October 2023</Typography>
            <Typography variant='body1'>
              FBarchive.org is now publicly accessible. 
              To coincide with the release, Nancy Gibbs, 
              the director of the Shorenstein Center at the Harvard Kennedy School, 
              discusses FBarchive in an op-ed published in 
              TIME Magazine titled &nbsp;
              <a style={linkStyle} href="https://time.com/6324817/managing-ai-social-media-disinformation/">“It will Take More than Robots to Manage the Robots”.</a>
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography variant='h4'>May 2023</Typography>
            <Typography variant='body1'>
              Over 200 individuals–students, staff, faculty, and outside
              researchers were given early access to the beta version of the
              Public Interest Tech Lab’s FBarchive to explore the usefulness of
              the archive prior to the platform’s release to the general public.
              The beta release contained the fully redacted set of documents.
              Those with beta access included a worldwide list of scholars,
              policymakers, and researchers from the United States, Norway, the
              United Kingdom, Australia, and the Philippines.
            </Typography>
            <img
              src={img2023May}
              style={imageStyle}
              alt='Public Interest Lab logo'
            />
          </Stack>
          <Stack spacing={2}>
            <Typography variant='h4'>April 2023</Typography>
            <Typography variant='body1'>
              Upon nearing the end of the construction process of the
              archive–and based on computer security best practices for the
              release of sensitive or security vulnerabilities in which the
              company is made aware of their public release–lawyers for Meta are
              given access to the redacted documents in order to provide further
              insight for the archive’s Privacy & Safety Board. With this
              information in hand, the board made its final recommendations on
              redaction policies to provide for the privacy and safety of the
              individuals and ethnic groups featured in the documents.
            </Typography>
            <Typography variant='body1'>
              The process, negotiated by Harvard’s Office of General Counsel and
              Meta, names the Public Interest Tech Lab as the final arbiter on
              what is presented. Meta’s review resulted in requests for 
              redactions to 816 images of the approximate 20,000 images 
              contained in FBarchive. Of these, 161 requests actually 
              influenced redactions made by the Public Interest Tech Lab. 
              Here is an accounting: 7 requests were about 
              User ID numbers redactions just like user names; 
              8 requests were about proprietary source code; 2 requests 
              concerned substantive internal legal counsel communications, and 144 
              requests identified employees that were not key decision makers. 
              The names of key Meta decision makers were not redacted and the 144 
              requests were helpful because Meta had better knowledge about who 
              were Meta employees in non-decision-making roles. Afterwards, the 
              archive’s Privacy & Safety Board finalized its <Link to="/redaction" style={linkStyle}>Redaction Guidelines for FBarchive</Link>.
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography variant='h4'>January 2023</Typography>
            <img
              src={img2023Jan}
              style={imageStyle}
              alt='Language Connections logo'
            />
            <Typography variant='body1'>
              The Public Interest Tech Lab contracted with translation firm,{' '}
              <a href='https://www.languageconnections.com/' style={linkStyle}>
                Language Connections
              </a>
              , to translate portions of the Facebook documents which feature
              content in languages other than English. The firm worked on 154
              images across 35 documents which included the following languages:
            </Typography>
            <Grid
              container
              rowSpacing={1}
              spacing={2}
              style={{
                marginLeft: '-16px',
                color: theme.palette.purple[5],
              }}
            >
              {[
                'Amharic',
                'Arabic',
                'Assamese',
                'Bengali',
                'Burmese',
                'Chinese',
                'French',
                'German',
                'Greek',
                'Hebrew',
                'Hindi',
                'Indonesian',
                'Japanese',
                'Korean',
                'Oromo',
                'Portuguese',
                'Romanian',
                'Russian',
                'Spanish',
                'Swedish',
                'Telugu',
                'Urdu',
                'Vietnamese',
              ].map((d, i) => (
                <Grid item>
                  <Typography sx={{ fontWeight: 800 }}>{d}</Typography>
                </Grid>
              ))}
            </Grid>
          </Stack>
          <Stack spacing={2}>
            <Typography variant='h4'>November 2022</Typography>
            <img
              src={img2022Nov}
              style={{ ...imageStyle, filter: 'invert(1)' }}
              alt='Knight Foundation logo'
            />
            <Typography variant='body1'>
              In Miami, at an event sponsored by the Knight Foundation, Dr.
              Latanya Sweeney presented short video clips about content found in
              the Facebook whistleblower documents to demonstrate the usefulness
              of the archive as an example of the burgeoning field of Public
              Interest Technology. The videos were produced by Tech Lab team members Leonie Beyrle (research), Jimmy Huettig (research), Eli Munn (animation), and Joshua Shank (script and narration).
            </Typography>
            <Typography variant='body1'>
              You may view them via the links below:
              <ul>
                <li>
                  Algorithmic failure
                  <ul>
                    <li>
                      A document seems to show evidence that lack of admin
                      approval has lead to violence in Ethiopia{' '}
                      <a
                        className='link'
                        href='https://drive.google.com/file/d/1J5uvLNnmf3Os_b-DLbfd-2v53J-5UTWd/view'
                        target='_blank'
                      >
                        [link]
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Translation issues
                  <ul>
                    <li>
                      A document in German shows that Facebook’s method for
                      dealing with non-English languages has the potential to
                      lead to fundamental misunderstandings about the content of
                      posts{' '}
                      <a
                        className='link'
                        href='https://drive.google.com/file/d/1CEzMnO_iOFswmmaGyJM1wTDoePdKIk6G/view'
                        target='_blank'
                      >
                        [link]
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  Language prioritization
                  <ul>
                    <li>
                      A document suggests Facebook’s method for the prioritizing
                      languages to monitor is based on employee conversations
                      rather than research{' '}
                      <a
                        className='link'
                        href='https://drive.google.com/file/d/1PiMpK7e2ebZVMFQ9CvzB0c6_Gyi77r2x/view'
                        target='_blank'
                      >
                        [link]
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography variant='h4'>April-June 2022</Typography>
            <Typography variant='body1'>
              Using the data collected by Harvard students, the Public Interest
              Tech Lab constructs visualizations of the content of the Facebook
              documents which describe the following information:
              <ul>
                <li>
                  <a href='https://fbarchive-topic-model.s3.amazonaws.com/index.html#topic=0&lambda=1&term=xx' style={linkStyle}>
                    Topic clusters
                  </a>
                </li>
                <li>
                  <a href='https://fbarchive.org/viz2' style={linkStyle}>
                    Topic relations
                  </a>
                </li>
                <li>
                  <a href='https://fbarchive.org/viz3' style={linkStyle}>
                    Document summaries
                  </a>
                </li>
                <li>
                  <a href='https://fbarchive.org/viz4' style={linkStyle}>
                    Countries and languages contained
                  </a>
                </li>
              </ul>
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography variant='h4'>February 2022</Typography>
            <Typography variant='body1'>
              In both Professor Sweeney’s undergraduate course at the Faculty of Arts and Sciences and graduate course at the Kennedy School of Government,
              Dr. Joan Donovan and Dr. Amelia Acker, with support
              from the Technology and Social Change Project presented research
              to students about topics which, if found in the documents, would
              be useful to other researchers. Using that lens of inquiry, the
              students then conducted a granular study on the contents and value
              of Facebook whistleblower documents as well as theorized the risks
              associated with their potential release to the public. Student research 
              involved analyzing the documents to determine the
              information contained, how it might be useful to civil society,
              journalists, regulators, policymakers, or academic researchers.
              Using a specifically constructed list of stakeholder concerns,
              students also conducted a risk assessment to speculate who might be
              harmed if the documents were disseminated carelessly.
            </Typography>
            <Typography variant='body1'>
              Using data the students collected, Public Interest Tech Lab team
              member Jordan Buchman constructed the following visualizations
              describing the contents of the Facebook whistleblower documents:
              <ul>
                <li>“Top 30 Most-Used Terms on the Topic of “Moderation”</li>
                <li>
                  “Top 30 Most-Used Terms on the Topic of “International hate
                  speech and inciting violence”
                </li>
                <li>
                  “Top 30 Most-Used Terms on the Topic of “Instagram and
                  negative body image”
                </li>
              </ul>
            </Typography>
            <img
              src={img2022Feb}
              style={{ ...imageStyle, filter: 'grayscale(0.9)' }}
              alt='Visualizations'
            />
          </Stack>
          <Stack spacing={2}>
            <Typography variant='h4'>January 2022</Typography>
            <img
              src={img2022Jan}
              style={imageStyle}
              alt='Public Interest Tech Lab logo'
            />
            <Typography variant='body1'>
              The Public Interest Tech Lab convened a Privacy & Safety Board for
              the archive made up of experts in the fields of intellectual
              property, data privacy, information policy, and computer science
              to advise on best practices for the safe release of whistleblower
              documents. Based on the policies created by the board, a Data
              Entry Team is formed to redact the documents to, among other
              things, ensure the safety of ethnic groups and the privacy of
              individuals–both inside and outside Meta–who are mentioned in the
              documents. Team members include (in alphabetical order):
              <span
                style={{
                  marginLeft: '8px',
                  color: theme.palette.purple[5],
                  fontWeight: 800,
                }}
              >
                Erika Brochu (lead), Bennet Comerford, Tanja Cooper,  Christian DeCoster (lead), Reeya Karki, and Linda Osei (lead).
              </span>
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography variant='h4'>January 2022</Typography>
            <Typography variant='body1'>
              Developers at the Public Interest Tech Lab had built 5 platforms, 
              with more than 20,000 lines of code, to view, tag, 
              and transcribe the photographs in preparation for public release. 
              Team members included (in alphabetical order): 
              Pascal Delpe-Brice, Eli Munn, and Latanya Sweeney.
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography variant='h4'>January 2022</Typography>
            <Typography variant='body1'>
              Dr. Amelia Acker of the University of Texas at Austin developed a
              bespoke citation standard for each image and document which takes
              into account the origin of the original document as well as the
              fact that the images were taken by Frances Haugen.
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography variant='h4'>November 2021</Typography>
            <img
              src={img2021Nov}
              style={{ ...imageStyle, filter: 'grayscale(0.9)' }}
              alt='FBarchive prototype'
            />
            <Typography variant='body1'>
              The Public Interest Tech Lab received an anonymous drop of the
              internal Facebook documents, which arrived as more than 2,000 PDF
              files that jointly contained over 20,000 photographic screenshots.
              Dr. Joan Donovan immediately recognized the valuable insight the
              documents provided to research on mis/disinformation, the
              challenges inherent in moderating social media at a global scale,
              and to public understanding of these phenomena. Seeing an
              opportunity to use the files to start a dialogue aimed at creating
              a safer digital environment, Dr. Latanya Sweeney and the{' '}
              <a href='https://techlab.org/' style={linkStyle}>
                Public Interest Tech Lab
              </a>{' '}
              at Harvard University, where she is the director, 
              began the design and development of FBarchive, 
              a legacy platform for the public release of whistleblower documents.
            </Typography>
            <Typography variant='body1'>
              The Shorenstein Center on Media, Politics and Public Policy at the
              Harvard Kennedy School of Government provided initial
              administrative and financial support for the creation of a
              first-of-its-kind archive for whistleblower documents. Media
              support was provided by the Public Interest Tech Lab, the
              Shorenstein Center, and the Technology and Social Change Project
              at Harvard. Technology design and development and document review platforms 
              and services were provided by the Public Interest Tech Lab. 
              FBarchive is born. Team members included (in alphabetical order):{' '}
              <span
                style={{
                  marginLeft: '8px',
                  color: theme.palette.purple[5],
                  fontWeight: 800,
                }}
              >
                Lisa Cohen, Joan Donovan, 
                Jimmy Huettig, Ellie Klerlein, 
                Laura Manley, Megan O’Neil, 
                Liz Schwartz, Joshua Shank, and Latanya Sweeney.
              </span>
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography variant='h4'>October 2021</Typography>
            <Typography variant='body1'>
              Gizmodo and a multidisciplinary research team that included Laura Edelson
              at New York University and Ethan Zuckerman at the University of Massachusetts
              collaborated to explore methods for making the internal Facebook documents
              publicly accessible. Copies of these documents had already been
              shared with select U.S. news organizations. This multidisciplinary team
              was first to investigate the balance between the public's right to
              know and respecting legal and ethical boundaries
              in handling leaked information. <Link style={linkStyle} to="/redaction-privacy">A copy of their
              guidelines appears here.</Link>
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography variant='h4'>October 2021</Typography>
            <img
              src={img2021Oct}
              style={{ ...imageStyle, filter: 'grayscale(0.9)' }}
              alt='Frances Haugen reveals herself as the Facebook whistleblower on 60 Minutes'
            />
            <Typography variant='body1'>
              In an interview on the CBS television news program,{' '}
              <i>60 Minutes</i>, Frances Haugen reveals herself as the Facebook
              whistleblower.
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography variant='h4'>Summer 2021</Typography>
            <Typography variant='body1'>
              Former Facebook employee Frances Haugen leaked internal documents
              to journalists at the <i>Wall Street Journal</i> who produced a
              series of valuable reports called{' '}
              <a
                href='https://www.wsj.com/articles/the-facebook-files-11631713039'
                style={linkStyle}
              >
                The Facebook Files
              </a>
              . Haugen subsequently made the files available to a select group
              of news organizations, resulting in national and international
              news coverage of problematic Facebook policies and practices that
              had not been disclosed to the public.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}
