import { useEffect, useState, useContext, useMemo } from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import {
  Container,
  Box,
  Stack,
  Modal,
  Typography,
  TextField,
  Button,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { motion } from 'framer-motion'
import _ from 'lodash'
import $axios from '../../tools/axiosWrapper'
import TagIcon from '@mui/icons-material/Tag'
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark'
import { MasterText } from '../home/master-text'

export const CollectionText = ({ }) => {
  const theme = useTheme()
  useEffect(() => {}, [])

  return (
    <Stack
      style={{
        width: '100vw',
        minHeight: '100vh',
      }}
      alignItems={'center'}
    >
      <Stack
        direction={'row'}
        spacing={2}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{ mt: 4 }}
      >
        <CollectionsBookmarkIcon sx={{ transform: 'scale(1.5)' }} />
        <Typography variant={'h4'}>collection (text-only view)</Typography>
      </Stack>

      <MasterText />

      <hr style={{width: "100vw"}}/>

      <Stack
        style={{
          width: '100vw',
          minHeight: '100vh',
        }}
        alignItems={'center'}
        sx={{ pr: 8 }}
      >
        <Stack
          direction={'row'}
          spacing={2}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ mt: 4 }}
        ></Stack>

        <Stack
          sx={{
            mt: 10,
            px: 4,
            py: 8,
            width: '80vw',
            maxWidth: 1000,
            // background: theme.palette.purple[0],
            borderRadius: '10px',
            overflowY: 'scroll',
          }}
          spacing={8}
        >
          <Stack sx={{ width: '100%' }} alignItems='center' textAlign='center'>
            <Typography marginBottom={2}>
              Thank you for your interest in FBarchive. In order to view these
              images, please log in by clicking the button below.
            </Typography>
            <Button
              variant={'contained'}
              size={'large'}
              onClick={() => {
                $axios.get('/auth/login').then((response) => {
                  window.location.href = response.data.login_url
                })
              }}
            >
              log in
            </Button>
          </Stack>

          <Stack sx={{ width: '100%' }} alignItems='center' textAlign='center'>
            <Typography marginBottom={2}>
              Don’t have an account? Sign up now by clicking the below button.
            </Typography>
            <Button
              variant={'contained'}
              size={'large'}
              onClick={() => {
                $axios.get('/auth/login').then((response) => {
                  window.location.href = response.data.login_url.replace(
                    'login',
                    'signup'
                  )
                })
              }}
            >
              sign up
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
